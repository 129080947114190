import React from "react";
import { IndexTable, Text, TextField, Select } from "@shopify/polaris";
import { DatePicker, InputNumber } from "antd";

const OrderTable = ({ data, setData, fieldError }) => {
  //   table name
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  //   Date picker
  const handleDateChange = (date, dateString) => {
    setData((prev) => ({ ...prev, dateofsupply: dateString }));
  };

  // field empty error
  const isError = (field) => {
    return fieldError[field] ? "can't be empty" : "";
  };

  return (
    <>
      {data && (
        <IndexTable
          resourceName={resourceName}
          itemCount={1}
          selectable={false}
          // onSelectionChange={handleSelectionChange}
          headings={[
            { title: "Order ID" },
            { title: "Full Name" },
            { title: "Country" },
            { title: "Pin Code" },
            { title: "Unit Price *" },
            { title: "Pieces *" },
            { title: "Freight Charge" },
            { title: "Invoice Number" },
            { title: "Insurance Charge" },
            { title: "Destination Duty VAT Charges" },
            { title: "Reverse Charge" },
            { title: "Insured Amount" },
            { title: "Invoice Value in Word" },
            { title: "Date of Supply" },
            { title: "Payer GST VAT ID" },
            { title: "No Foreign Exchange Involved *" },
          ]}
        >
          <IndexTable.Row>
            <IndexTable.Cell>
              <Text>{data.name}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text>
                {data.customer?.first_name + " " + data.customer?.last_name}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text>
                {data.shipping_address ? data.shipping_address.country : "-"}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text>{data.shipping_address?.zip}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text>{data?.total_line_items_price}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ width: "20px !important" }}>
                <InputNumber
                  // !==undefined?data.pieces:data.line_items?.length
                  value={data.pieces}
                  onChange={(val) =>
                    setData((prev) => ({ ...prev, pieces: val }))
                  }
                  controls={false}
                />
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                style={{ width: "100%" }}
                value={data.FreightCharge}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, FreightCharge: val }))
                }
                error={isError("FreightCharge")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="text"
                style={{ width: "100%" }}
                value={data?.InvoiceNumber || ""}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, InvoiceNumber: val }))
                }
                error={isError("InsuranceCharge")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                value={data.InsuranceCharge}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, InsuranceCharge: val }))
                }
                error={isError("InsuranceCharge")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                value={data.DDutyVatCharge}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, DDutyVatCharge: val }))
                }
                error={isError("FreightCharge")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                value={data.ReverseCharge}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, ReverseCharge: val }))
                }
                error={isError("ReverseCharge")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                value={data.InsuredAmount}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, InsuredAmount: val }))
                }
                error={isError("InsuredAmount")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                value={data.Invoicevalueinword}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, Invoicevalueinword: val }))
                }
                error={isError("Invoicevalueinword")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ width: "120px" }}>
                <DatePicker onChange={handleDateChange} />
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <TextField
                type="number"
                value={data.PayerGSTVAT}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, PayerGSTVAT: val }))
                }
                error={isError("PayerGSTVAT")}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Select
                label=""
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
                onChange={(val) =>
                  setData((prev) => ({ ...prev, NFEI_FLAG: val }))
                }
                value={data.NFEI_FLAG}
                error={isError("NFEI_FLAG")}
              />
            </IndexTable.Cell>
          </IndexTable.Row>
        </IndexTable>
      )}
    </>
  );
};

export default OrderTable;
