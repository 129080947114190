import axios from "axios";

const URL = "https://orderlabelgenerate.marmeto.com";
axios.interceptors.request.use(config => {

      const token = localStorage.getItem('token');
      console.log("Intercepor Config:", config);
      // console.log("Token:", token);
      config.headers = {
          ...config.headers, 
         "Authorization": token
      };
      config.url = `${URL}${config.url}`;
      console.log("Headers:", config.headers);
    return config;
});

axios.interceptors.response.use(resp => {
  
  console.log("Intercepor Config:", resp);
  console.log("Response:", JSON.stringify(resp?.data));
  if(resp?.data.error){

    alert("It seems authentication token is expired, missing or invalid. Please go to the store and relogin.");
  }
  return resp;
})

// const instance = axios.create({
//   //  baseURL: "https://orderlabelgenerate.marmeto.com",
//   baseURL: "https://dhl.in.ngrok.io"
// });


export default axios;
