export default function countObjectsByID(objects) {
  var count = {};
  var totalQuantity = {};

  for (var i = 0; i < objects.length; i++) {
    var id = objects[i].id;
    var quantity = objects[i].fulfillable_quantity;

    if (count.hasOwnProperty(id)) {
      count[id] += 1;
      totalQuantity[id] += quantity;
    } else {
      count[id] = 1;
      totalQuantity[id] = quantity;
    }
  }

  return { count, totalQuantity };
}