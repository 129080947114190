import React, { Component, Fragment } from "react";
// import "@shopify/polaris/styles.css";
import { AppProvider, FooterHelp } from "@shopify/polaris";
import { NavLink, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Order from "./order/Order";
import Settings from "./Settings";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import EditShip from "./EditShip";
import "./admin.scss";
import Error from "./Error";

class AdminHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      shop_url: "",
      url: "",
    };
  }

  componentDidMount() {

    let url = this.props.match.url;
    url = url.split("/");
    let token = url.pop();
    url = url.join("/");
    let shop_url = this.props.match.params.store;
    const pattern = /(\..*){2,}/;
    if(pattern.test(token))
      localStorage.setItem("token", token);

    console.log("URL", url, "token", token);
    this.setState({ 
      ...this.state,
      url,
      shop_url,
    });
  }

  render() {
    // console.log(this.state);
    const { url } = this.state;
    return (
      <AppProvider>
        <Fragment>
          <div className="navbar">
            <div className="navbar_wrapper">
              <ul className="ul_list">
                <li className="li_list">
                  <NavLink
                    activeClassName="selected"
                    className="link"
                    to={`${url}/1`}
                    exact
                  >
                    Home
                  </NavLink>
                </li>
                <li className="li_list">
                  <NavLink
                    activeClassName="selected"
                    className="link"
                    to={`${url}/order`}
                    exact
                  >
                    Order
                  </NavLink>
                </li>
                <li className="li_list">
                  <NavLink
                    activeClassName="selected"
                    className="link"
                    to={`${url}/settings`}
                  >
                    Settings
                  </NavLink>
                </li>
                {/* <li className="li_list">
                  <NavLink
                    activeClassName="selected"
                    className="link"
                    to={`${url}/checkbox`}
                  >
                    CheckboxTest
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="main_section">
            <Switch>
              <Route path="/admin_home/:store/settings" component={Settings} />
              <Route path="/admin_home/:store/edit-ship" component={EditShip} />
              <Route path="/admin_home/:store/order" component={Order} />
              {/* <Route path="/admin_hone/error" component={Error} /> */}
              <Route
                path="/admin_home/:store/privacy"
                component={PrivacyPolicy}
              />
              {/* <Route
                path="/admin/:store/checkbox"
                component={MultipleChecckbox}
              /> */}

              <Route path="/admin_home/:store/:token" component={Dashboard} />
            </Switch>
          </div>
          <FooterHelp>
            Feel free to reach out at{" "}
            <a href="mailto:EcommerceHelpdesk@dhl.com">
              EcommerceHelpdesk@dhl.com
            </a>{" "}
            or <a href="tel:1800 111 3345">1800 111 3345</a> in case of any
            queries.
            <a href={`${url}/privacy`}> Privacy Policy </a>
          </FooterHelp>
        </Fragment>
      </AppProvider>
    );
  }
}

export default AdminHome;
