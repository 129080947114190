import React, { Fragment } from "react";
import "./privacy.css";

const UBR = ({text,url}) => {

	return (
		<a href={url}>
			<b><u style={{color:"red"}}>{text}</u></b>
		</a>
	);
}

let dhl_express = "https://mydhl.express.dhl/index/en.html";
let dhl_globle_forarding = "https://www.dhl.com/in-en/home/our-divisions/global-forwarding.html";
let dhl_friegth = "https://www.dhl.com/in-en/home/our-divisions/freight.html";
let dhl_parcel = "https://www.dhl.com/global-en/home/our-divisions/parcel.html";
let dhl_e_s = "https://www.dhl.com/global-en/home/our-divisions/ecommerce-solutions.html";
let dhl_packet = "https://www.dhl.de/de/privatkunden.html";
let mydhl ="https://mydhl.express.dhl/index/en.html";
let mydhli = "https://www.mydhli.com/global-en/home/footer/privacy-notice.html";
let mydhl_parcel = "https://www.dhlparcel.nl/en/your-personal-data";
let business_unit = "https://www.dhl.com/in-en/home/our-divisions.html";
let customer_portel = "https://www.dhl.com/in-en/home/login.html#parsysPath_headline_quicklinks";
let who_is_responsible = "https://www.dhl.com/in-en/home/footer/privacy-notice.html#parsysPath_component_accordion_";
let more_info = "https://careers.dhl.com/global/en";
let here = "https://www.dpdhl.jobs/";
let binding_cooprate_rule = "https://www.dpdhl.com/en/data-protection.html";
let privacy_pref_centre = "https://www.dhl.com/in-en/home/footer/privacy-notice.html#cookiepref";
let dhl_upply_chain = "https://www.dhl.com/in-en/home/our-divisions/supply-chain.html";



const PrivacyPolicy = () => {

	return (
		<Fragment>
			<div className="privacy">
				<div className="heading">

					Privacy Notice
				</div>
				<div className="body">

					<p> Deutsche Post AG and its subsidiaries operating under the brand DHL 
(hereinafter called 'DHL') is pleased that you have visited our website and are 
interested in our company, products and services. It is important to us to protect
your personal data during handling throughout the entire business process. </p>

					<p> In the following, we explain what information DHL collects when you visit our 
website and how this information is used. </p>

					<p> This Privacy Notice applies to all users of our services, websites, applications, 
features or other services anywhere in the world, unless covered by a separate 
Privacy Notice. This Privacy Notice applies to the following categories of 
individuals: <br/><br/>
					<ul style={{listStyleType:"square"}} >
						<li>Shippers: shippers, including their employees, or individuals who send a 
				shipment</li>
						<li>Shipment receivers: any individual who receives a shipment</li>
						<li>Persons showing interest in us and our services</li>
						<li>Business partners: business partners, including their employees</li>
						<li>Employment candidates: individuals that apply for a job with us</li>
					</ul>All the above subjects are referred to as “you” or “your”.<br/> <br/>
				</p>

				<p> 
						<b><u>What is Personal Data?</u></b><br/><br/>
						Personal data means any information relating to an identified or identifiable 
natural person ('data subject'); an identifiable natural person is one who can be 
identified, directly or indirectly, in particular by reference to an identifier such as
a name, an identification number, location data, an online identifier or to one or 
more factors specific to the physical, physiological, genetic, mental, economic, 
cultural or social identity of that natural person. This includes information such 
as your real name, address, telephone number and date of birth. Information 
which cannot be linked to your real identity - such as favorite websites or 
number of users of a site - is not considered personal data.
					</p>

					<p> 
						<b><u>Who is Responsible?</u></b><br/>
						This Privacy Notice applies for the data processing carried out by:<br/><br/>
Deutsche Post AG<br/>
Charles-de-Gaulle-Str. 20<br/>
53113 Bonn<br/>
Germany<br/><br/>

Data Protection Officer of controller, contact details:<br/><br/>
Deutsche Post AG<br/>
Global Data Protection<br/>
53250 Bonn<br/><br/>
If you have queries with regard to the processing of your personal data, please 
contact the Data Protection Officer.<br/><br/>
If you have any further queries regarding data protection in connection with our 
website or the services offered there, please contact data protection:<br/>
<br/>
<a href="https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-contact.html">https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-contact.html</a>
<br/><br/>	</p>


					<p> 
						<b><u>Types of Data We Process</u></b><br/><br/>
						We only process your personal data when required for a specific and allowed 
purpose.<br/><br/>
					<ul >
						<li>
						<b>CONTACT DATA</b><br/>
						Information to contact you e.g. when you use one of our services.<br/>
Such as: your name, phone number, address, email address
						</li><br/>

						<li>
						<b>PROFILE DATA</b><br/>
						Information on your dhl profile e.g. to set your delivery preferences.<br/>
Such as: your delivery preferences, order<br/>
						</li><br/>

						<li>
						<b>SHIPMENT DATA</b><br/>
						Information enabling the pick-up and delivery of your shipment e.g. status of 
your shipment.<br/>
Such as: your address, shipment documents, shipment no.
						</li><br/>

						<li>
						<b>SURVEY DATA</b><br/>
						Information communicated by you to one of our employees or by filling in an 
online survey e.g. when you call our customer service.<br/>
Such as: your name, email, phone number
						</li>
					</ul>

					</p>


					<p> 
						<b><u>What Are the Purposes of and the Legal Basis for the Processing of 
Personal Data?</u></b><br/><br/>
						
					<ul >
						<li>
						<b><u>Visiting our Website</u></b><br/><br/>
						DHL is committed to preserving the privacy of users of our websites. 
When you visit our web pages, The following data are collected: IP address, hostname of the accessing computer, website from which you 
accessed this website, a list of the sites you visited within the scope of our
overall Internet presence, the date and duration of your visit, notification 
of whether the visit was successful, volume of data transferred, 
information about the identification data of the browser type and 
operating system used by you.  Temporary storage of this data is 
necessary during your visit to the website in order to allow the website to 
be delivered to you. Further storage in log files is performed to ensure the
functionality of the website and the security of our IT systems. The legal 
basis for the processing of the aforementioned data categories is 
therefore Art. 6 (1) (f) of the European General Data Protection Regulation
(GDPR). Due to the said purposes, in particular to guarantee security and 
a smooth connection setup, we have a legitimate interest to process this 
data.<br/><br/>

Additional personal information such as your name, address, telephone 
number or e-mail address is not collected unless you provide this data 
voluntarily, e.g. while completing an online contact form, as part of a 
registration, survey, competition or an information request.<br/><br/>

The data are deleted as soon as they are no longer needed to achieve the 
purpose for which they were collected. For the provision of the website, 
this is the case when the session terminates. The log files (access logs) 
are kept for administrators to access directly and exclusively for a period 
of 24 hours. After that, they are only available indirectly through a restore
from backup tapes and are permanently deleted after 30 days.<br/><br/>	

As far as you have enabled geo localization functions in your browser, 
respectively in your operating system we will use this data to offer you 
location-based services (e.g. location of the nearest branch, packing 
station etc.). The legal basis for geo-localization is your consent according 
Art. 6 (1) (a) GDPR by enabling the respective function. We will not use 
this data for any other purpose. If you disable this function your data will 
be deleted in due time.<br/><br/>
						</li>

						<li>
						<b><u>Use of Cookies</u></b><br/><br/>
						This website uses cookies and similar technologies, (hereafter 
“technologies”), which enable us, for example, to determine how 
frequently our internet pages are visited, the number of visitors, to 
configure our offers for maximum convenience and efficiency and to 
support our marketing efforts.<br/><br/>

When you access our website, a <UBR text="Privacy Preference Center" url={privacy_pref_centre} /> will inform 
you that technically necessary cookies are set and, in addition, that your 
consent is required to the use of certain technologies, such as analytical 
cookies or similar technologies.<br/><br/>

The processing of data by means of technically necessary cookies is 
based on our legitimate interest according to Art. 6 (1) f) GDPR to ensure 
that you can use our website the best possible way, to ensure that our 
website operates correctly and to guarantee an convenient and 
undisturbed user experience. These kinds of cookies are stored 
exclusively for the duration of your visit to our website. They are 
automatically deleted when you close your browser.<br/><br/>

In addition, you will be informed via the <UBR text="Privacy Preference Center" url={privacy_pref_centre} />  
that we use other technologies  (performance, functional or analytics 
technologies) for storing information about visitors who repeatedly access
one of our internet pages. The purpose of using these technologies is to 
be able to offer you optimal user guidance as well as to "recognize" you 
and thus be able to present (as much as possible) diversified internet 
pages and new contents during repeated use. These technologies will only
be processed on the basis of your prior given consent according to Art. (1)
a) GDPR.<br/><br/>

You will find more information under the <UBR text="Privacy Preference Center" url={privacy_pref_centre} />  
including the option to revoke a given consent.<br/><br/>

Notwithstanding the information in the Preference Center, and only if you 
have consented to the use of cookies and other technologies as described
above, we also use them for the purposes described below.<br/><br/>
						</li>

						<li>
						<b><u>Social Media </u></b><br/><br/>
						We provide you our social media fan pages, such as Facebook, Instagram 
or LinkedIn to give you even more insights in our services and products, 
to better manage our relationship with you and to communicate with you. 
For more information, please visit our Privacy Notices on our social media 
fan pages.<br/>

Therefore, our website contains links to the following social media 
networks:
<br/><br/>
<ul>
	<li>Facebook (operated by: Facebook Inc., 1601 S. California Ave, Palo 
Alto, CA 94304, USA)</li>
<li>Instagram (operated by: Facebook Inc., 1601 S. California Ave, Palo 
Alto, CA 94304, USA)</li>
<li>LinkedIn (operated by: LinkedIn Ireland Unlimited Company, Wilton 
Place, Dublin 2, Ireland)</li>
<li>YouTube (operated by: Google Inc., 1600 Amphitheatre Parkway, 
Mountain View, CA 94043, USA)[TM(D1] [KI2]</li>
</ul><br/>
In these cases, a transfer of data to the social media operators mentioned
before only takes place if the corresponding icon (e.g. the "f" of Facebook)
is clicked. If you click on one of these icons, a page of the corresponding 
social media operator opens in a separate window.<br/><br/>
						</li>
					</ul>
					</p>

					<p> 
						<b><u>Performance of a Contract</u></b><br/><br/>
						For pre-contractual and contractual reasons, we also need personal data to 
provide our services and comply with the obligations arising from contractual 
agreements concluded with you.<br/><br/>
On dhl.com we offer the following services:<br/><br/>
					<ol>
						<li> Get a Quote </li>
						<li>DHL for Business</li>
						<li>Track & Trace</li>
						<li>Ship Now</li>
						<li>Customer Service</li>
					</ol>
					<ul >
						<li>
						<b><u>Get a Quote</u></b><br/><br/>
						In order to get back to you via email or phone to make you an offer, you 
can use one of our <b>Get a Quote</b> services. Depending on the country you 
are residing and in order to get you to the right expert team or tool within 
DHL, we either ask whether you would like to ship parcels or documents, 
cargo / freight or international business mail and whether you are a 
business or private customer.<br/><br/>

Based on this information your request will be directed to our business 
units  [<UBR text="DHL Express" url={dhl_express} />, <UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, 
<UBR text="DHL Parcel" url={dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Paket" url={dhl_packet} />] and you will 
immediately receive a product card with price and further details from the
business unit(s) which offer best suits your request. It could also be that 
we ask you for the following details: country of origin and destination 
including city and postcode (we need an approximate origin and 
destination in order to give you a quote for your shipment as accurate as 
possible) and weight and dimension of the shipment. Depending on your 
request we might also ask the quantity, whether documents or dangerous 
goods are involved or whether it is palletized or stackable. This 
information is also forwarded to one of our business units  [<UBR text="DHL Express" url={dhl_express} />, <UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, <UBR text="DHL Parcel" url={dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Paket" url={dhl_packet} />]  and you will immediately receive a
product card shown with price and further details regarding your shipment
and optional add-ons.<br/><br/>

By clicking on the “continue to booking” button you will be forwarded to 
the respective business unit ́s  booking tool system 
[<UBR text="MyDHL+" url={mydhl} />, 
<UBR text="myDHLi" url={mydhli} />, 
<UBR text="MyDHL Parcel" url={mydhl_parcel} />]. Hence, on dhl.com we basically do 
not collect or otherwise process your personal data for our Get a Quote 
service. However, if data is nevertheless considered personal, such as the 
city and postcode, the legal basis for this is the contract in progress (Art. 6
(1) b) GDPR) and a legitimate interest in being able to submit our offer as 
accurately as possible (Art. 6 (1) f) GDPR).<br/><br/>	

Provided that no statutory or contractual retention periods needs to be 
observed, your request will be stored for a maximum of 30 days with the 
relevant business unit which finally made the quote as proof of proper 
processing and for the purpose of further optimizing services. On dhl.com 
the respective data is stored for a maximum of 30 days if you have used 
the safe shipment service and unless not earlier deletes by yourself.<br/><br/>
						</li>

						<li>
						<b><u>DHL for Business</u></b><br/><br/>
						If you are using or <b>DHL for Business</b> service we ask you for the shipping 
destination, the volume and the frequency of the shipment. Based on this 
information and your shipping needs, your request will be send to our 
business units [<UBR text="DHL Express" url={dhl_express} />, 
<UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, 
<UBR text="DHL Parcel" url={dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Paket" url={dhl_packet} />]  and 
allocated to those which services best fit your requirements. In order to 
get in touch with you as soon as possible we also ask you for your 
company ́s name, your name, business address, email address and phone
number. This data will also be forwarded to the respective business units 
and is necessary to take steps at your request prior to entering into a 
contract and / or for the performance of a contract. The legal basis is 
therefore Art. 6 (1) b) GDPR.<br/><br/>

Provided that no statutory or contractual retention periods need to be 
observed, your request will be stored for a maximum of 30 days with the 
relevant business unit which finally made the quote as proof of proper 
processing and for the purpose of further optimizing services.<br/><br/>

						</li>

						<li>
						<b><u>Track & Trace </u></b><br/><br/>
						You can use the <b>Track and Trace</b> service to track shipments addressed 
to you or sent by you, and also view the expected time of delivery. To this
end the tracking information is sent to the respective <UBR url={business_unit} text=" business unit"/> to 
find the status of the shipment. You will also receive all important detailed
information about your shipment from the respective business unit. In 
addition, the sender or recipient can also receive further information 
about the shipment in question by e-mail or SMS. For this purpose, the e-
mail address or mobile phone number of the recipient of the notification 
can be stored and the notification activated. In this context, the person 
entering the data must ensure that the notification recipient agrees to the
processing of e-mail address, mobile phone number and notification.The following data are processed as part of shipment tracking: shipment 
number, name and address of the sender, name and address of the 
recipient, e-mail address of the sender/recipient if applicable, name and 
address of the alternative recipient if applicable, customer data of the 
sender, product or service name, shipment history, signature of the 
recipient, confirmation that a minimum age has been checked (over 16/over 18).<br/><br/>

The data are processed for the purpose of fulfilling the contract on the 
basis of Art. 6(1) (b) GDPR. <br/><br/>


To enhance user convenience, when using shipment tracking DHL 
automatically generates a list of the last shipment number searched for 
via the shipment search: This function saves you having to re-enter the 
shipment number when checking the shipping status of your shipment. In 
this context, you can name individual shipments according to your 
preference (“Shipment title"). For this purpose, the application saves a list
of the last shipment searches in a cookie..<br/><br/>
						</li>

						<li>
						<b><u>Ship Now</u></b><br/><br/>
						Our <b>Ship Now</b> service leads you to our various customer portals of our 
business units [<UBR text="DHL Express" url={dhl_express} />, 
<UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, 
<UBR text="DHL Parcel" url={dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Paket" url={dhl_packet} />] (e.g. 
MyDHL+, DHL eCommerce Web Portal, my DHLi or DHL Interactive). You 
can also continue as guest and you will be forwarded to our business unit 
which best meets you shipping needs. In any case we will not process 
your personal data at this stage. Same applies if you would like to 
schedule a redelivery. After a few questions about your shipment you will 
be directed to our various <UBR text="customer portals" url={customer_portel} /> where you can arrange the 
redelivery in detail.<br/><br/>
						</li>

						<li>
						<b><u>Customer Service</u></b><br/><br/>
						You can contact us at any time with questions via our <b>Customer Service</b>.
Depending on the respective business unit, you will either be forwarded 
directly to the website of the respective business unit or we ask you to fill 
out a contact form beforehand, which requests the following data 
categories and then forwards them to the respective responsible business 
unit: your customer reference no., your name, the name of your company,
postal address, email address and telephone no. country / region and the 
sector you are working for. The legal basis is Art. 6 (1) (b) GDPR, as this 
processing is carried out in order to prepare or to fulfil a contract. 
Furthermore, you provide the data voluntarily for the purpose of 
contacting us, so your consent is another legal basis, Art. 6 (1) (a) GDPR.In addition you have the option to consent to promotional emails about 
our services. To this end we also forward your contact data to our 
business units [<UBR text="DHL Express" url={dhl_express} />,  
<UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, 
<UBR text="DHL Parcel" url={dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Paket" url={dhl_packet} />]. The 
legal basis for this is your consent according to Art. 6 (1) (a) GDPR.<br/><br/>
						</li>
					</ul><br/>
					Further information on data protection in specific services and products is 
available at the relevant divisional Customer Portal and / or the respective local 
privacy notice.<br/><br/>
<a href="https://www.dhl.com/in-en/home/login.html">https://www.dhl.com/in-en/home/login.html</a>
<br/><br/>
					</p>


					<p> 
						<b><u>Processing for Advertising Purposes</u></b><br/><br/>
						When you visit our web page we offer you a broad variety of information about 
DHL, about our products, services and events. In particular you can get 
information about our:<br/><br/>
					<ol>
						<li>Events & Webinars</li>
						<li>Delivered</li>
						<li>Spotlight</li>
						<li>Newsletters</li>
					</ol>
					<ul >
						<li>
						<b><u>Events & Webinars</u></b><br/><br/>
						If you would like to participate in one of our <b>Events</b> and/or <b>Webinars</b>, 
you will be redirected to the appropriate registration page. There we 
basically collect the following personal data: your name, your email 
address and who is your main contact at DHL. Depending on the 
respective sector we may also ask for your company name, your seniority 
and whether you have previously done business with DHL. The legal basis 
is Art. 6 (1) (b) GDPR, as this processing is carried out in order to fulfil the 
contract (attending the event).<br/><br/>
						</li>

						<li>
						<b><u>Delivered</u></b><br/><br/>
						You have the option there to consent to promotional emails about our 
services. To this end we also forward your contact data to our business 
units [<UBR text="DHL Global Forwarding" url={dhl_globle_forarding} />, <UBR text="DHL Freight" url={dhl_friegth} />, 
<UBR text="DHL Parcel" url={ dhl_parcel} />, <UBR text="DHL eCommerce Solutions" url={dhl_e_s} />, 
<UBR text="DHL Supply Chain" url={dhl_upply_chain} />]. The legal basis for this is 
your consent according to Art. 6 (1) (a) GDPR. You may unsubscribe at 
any time by clicking the relevant link at the bottom of the Spotlight mail. 
You can also revoke the consent at any time with effect for the future 
using the contact address provided.<br/><br/>
						</li>

						<li>
						<b><u>Spotlight</u></b><br/><br/>
						If you subscribe to DHL <b>Spotlight</b> to know first about new articles we only
collect your email address and it is not used for any other purpose. The 
legal basis for this is your consent according to Art. 6 (1) (a) GDPR. You 
may unsubscribe at any time by clicking the relevant link at the bottom of
the Spotlight mail. You can also revoke the consent at any time with effect
for the future using the contact address provided. If you unsubscribe from 
Spotlight or revoke your consent, the corresponding data will be removed 
or blocked from the mailing list and will no longer be processed for these 
purposes.
Notwithstanding the foregoing, if you are an existing DHL customer, your 
postal data (e.g. name, address) will be processed to contact you in order 
to provide you with the latest information about our offers, news, products
and services. Apart from an existing consent, which is based on Art. 6 (1) 
a) GDPR, we will process your e-mail address exclusively in order to 
provide you with information regarding DHL own and similar products. 
Legal basis for the aforementioned processing to keep you updated 
regarding our own and similar products is Art. 6 (1) f) GDPR. The 
processing of customer data for own direct marketing purposes is 
regarded as carried out for a legitimate interest. You have the right to 
object at any time to the said processing. To exercise your right, simply 
get in touch with us by using the contact details mentioned under <UBR text="Who is Responsible" url={who_is_responsible} /> <br/><br/>
						</li>

						<li>
						<b><u>Newsletters</u></b><br/><br/>
						f you register for one of our <b>newsletters</b>, we are entitled to use your e-
mail address for this purpose. The legal basis for this data processing is 
your consent pursuant to Art. 6 (1) (a) GDPR. You may unsubscribe from 
the newsletter at any time by clicking the relevant link at the bottom of 
the newsletter. You can also revoke the consent at any time with effect for
the future using the contact address provided. If you unsubscribe from our
e-mail communications or revoke your consent, the corresponding data 
will be removed or blocked from the mailing list and will no longer be 
processed for these purposes.
Including your e-mail address in a blocking list is effected in order to 
safeguard our legitimate interests pursuant to Art. 6 (1) (f) GDPR. Our 
legitimate interest lies in not sending you any information by e-mail in the
future.<br/><br/>
						</li>
					</ul>
					</p>

					<p> 
						<b><u>Data Retention & Third Parties</u></b><br/><br/>
					<ul >
						<li>
						<b><u>Jobs and Careers at Deutsche Post and DHL</u></b><br/><br/>
						Career opportunities within Deutsche Post DHL Group are as diverse as 
our teams all over the world. With approximately 570,000 employees in 
over 220 countries, we connect people, improving their lives. If you would 
like to apply for one of our open jobs you will find <UBR text="more information here" url={more_info}/> and, also on data protection,
<UBR text="here" url={here} /><br/><br/>
						</li>

						<li>
						<b><u>How Long We Keep Your Data</u></b><br/><br/>
						We will keep your data for as long as necessary to fulfill our purposes, to 
execute our contracts and to comply with any legal obligation. The 
retention period may differ per country based on applicable country laws.<br/><br/>
We continuously strive to minimize the retention period of data where the 
purpose, the law or contracts allows us to do so. The data that we collect 
based on your consent will be kept until you withdraw your consent.<br/><br/>
						</li>

						<li>
						<b><u>Will My Data Be Passed On</u></b><br/><br/>
						DHL does not share, sell, transfer or otherwise disseminate your personal 
data to third parties and will not do so in future, unless required by law, 
unless required for the purpose of the contract or unless you have given 
explicit consent to do so. For instance, it may be necessary to pass on 
your address and order data to our contractors when you order products. 
Further information on data protection in specific services and products is 
available at the relevant <UBR text="Customer Portal" url={customer_portel} />.<br/><br/>
However, in the context of the data processing as described in this Privacy
Notice and the respective legal basis given, DHL will transfer your data to 
the following categories of recipients:<br/><br/>
							<ul>
								<li>DPDHL Group companies: transfer is required within the DPDHL 
Group, its legal entities and shared service centers to provide and 
improve our products and services (e. g. our Get a Quote or 
Frequent Shipment Services). Transfers can also occur to manage 
our relationship with you, to identify you, to answer your requests 
and to comply with your privacy rights.</li>
<li>Third party business partners: The transfer to third party business 
partners is limited to what is required by applicable law and / or in 
order to fulfill our contractual obligations.</li>
<li>Third party processors: which process data are contractually obliged
to maintain strict confidentiality as per Art. 28 GDPR. DHL retains 
responsibility for safeguarding your personal data in such 
circumstances. The business partners follow the instructions of DHL,
and this is guaranteed by technical and organizational measures, as
well as by means of checks and controls.</li>
<li>Public authorities: transfer is required by applicable law (e.g. to fulfil
a legal obligation during shipment processing).</li>

							</ul>
							Your data is only transferred outside the European Economic Area (EEA) to
other Deutsche Post DHL Group companies, third party business partners 
or public authorities when permitted by applicable data protection law. In 
such cases, we will make sure that appropriate safeguards are in place to 
ensure the transfer of your data (e.g. our <UBR text="binding corporate rules" url={binding_cooprate_rule} />, 
standard contractual clauses)
						</li>
					</ul>
					</p>

					<p> 
						<b><u>What Are My Data Subject's Rights?</u></b><br/><br/>
						You have the following rights:

						<ul style={{listStyleType:"square"}} >
							<li>You can request information as to what personal data is stored</li>
							<li>You can request that we correct, delete or block your personal data
							provided these actions are permitted by law and in compliance with 
							existing contractual conditions.</li>
							<li>You can request to receive personal data you have provided in a 
							structured, commonly used and machine-readable format.</li>
							<li>You may lodge a complaint with the supervisory authority</li>
						</ul>

						<ul>
						<li>
							<b><u>Right to Obtain</u></b><br/><br/>
							You can obtain your information by contacting data protection here:<br/>
 							<a href="https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-
contact.html">https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-
contact.html</a><br/><br/>
						</li>

						<li>
							<b><u>Right to Object</u></b><br/><br/>
							The right to object applies for all processing of personal data which is 
based on Art. 6 (1) f) GDPR.<br/><br/>
						</li>


						<li>
							<b><u>Right to Withdraw Your Consent</u></b><br/><br/>
							You have the right to withdraw your consent with effect for the future at any 
time.<br/>To exercise your right, simply get in touch with us by using the contact details 
mentioned under "Who is Responsible" or via the button below:<br />
 							<a href="https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-contact.html">https://www.dhl.com/in-en/home/footer/privacy-notice/data-protection-contact.html</a><br /><br/>
						</li>

						</ul>
					</p>

					<p> 
						<b><u>What Are My Data Subject's Rights?</u></b><br /><br />
						DHL takes the security of your data very seriously. We have implemented 
various strategies, controls, policies and measures to keep your data secure. We
keep our security measures under close review. We use safeguards such as 
firewalls, network intrusion systems, and application monitoring. Where 
appropriate, we secure your data by using pseudonymization and encryption 
techniques when storing and transferring your data. For instance, your data is 
saved in a secure operating environment which is not accessible to the public. In
certain cases, your personal data is encrypted by Secure Socket Layer 
technology (SSL) during transmission. This means that an approved encryption 
procedure is used for communication between your computer and the DHL 
servers if your browser supports SSL. We ensure that there are strict physical 
access controls in our buildings and certified data centers.<br /><br />
As a part of our security strategy, we have set up auditing programs to make 
sure that our systems and services comply with the DPDHL information security 
policy, and by extension the ISO 27001 standard.<br /><br />
In addition, we are taking a number of ongoing measures to reduce risk, such as
(but not limited to) training our employees regularly and organizing incident 
simulation exercises by our Cyber Defense Center.<br /><br />
Should you wish to contact DHL by e-mail or contact forms, we would like to 
point out that the confidentiality of the information sent cannot be guaranteed. 
The contents of (e-mail) messages can be read by third parties. We therefore 
recommend you send us confidential information only by post.<br /><br />
					</p>

					<p> 
						<b><u>Where Do I Find Service Specific Information Regarding the Processing of Personal Data?</u></b><br /><br />
						Further information on data protection in specific services and products is 
available at the relevant <UBR text="Customer Portal" url={customer_portel} />.<br /><br />
					</p>
					<p> 
						<b><u>DPDHL Data Privacy Policy</u></b><br /><br />
						The "DPDHL Data Privacy Policy" regulates the Group-wide standards for data 
processing with a special focus on so-called third country transfers, meaning 
transfers of personal data to countries outside the EU, which do not have an 
adequate level of data protection. If you are interested in learning more about 
the "DPDHL Data Privacy Policy"<br /><br />

					</p>

					<p> 
						<b>Changes to Privacy Notice</b><br /><br />
						DHL reserves the right to change its Privacy Notice at any time with or without 
prior notice. Please check back frequently to be informed of any changes. By 
using DHL's websites you agree to this Privacy Notice.<br /><br />
This statement was last updated on:<br /><br />
 10/09/2021<br /><br />
					</p>
				</div>
			</div>
		</Fragment>
	);
}

export default PrivacyPolicy;