import React, { Component, Fragment } from "react";
import { Card } from "@shopify/polaris";
import axios from "../axios";
import Spinner from "./UI/Spinner";
import "../Sass/pages/dashboard.scss";
import { Modal } from "antd";

class Dashboard extends Component {
  state = {
    allOrder: "",
    newOrder: "",
    processedOrder: "",
    isLoading: false,
    showNotification: true
  };

  async componentDidMount() {
    let url = this.props.match.url;
    let shop_url = this.props.match.params.store;

    try {
      this.setState({ isLoading: true });
      const res = await axios.get(`/admin/home/${shop_url}`);
      console.log(res);
      let data = res.data;
      if(data.error){

        this.setState({
          allOrder: 0,
          newOrder: 0,
          processedOrder: 0,
          isLoading: false
        });
      }else{

        this.setState({
          allOrder: data.allCount,
          newOrder: data.newCount,
          processedOrder: data.processedCount,
          isLoading: false
        });
      }
    } catch (error) {

      console.log(error);
      this.setState({ isLoading: false });
      alert("something went wrong", error.message);
    }
  }

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      showNotification: false,
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      showNotification: false,
    });
  };
  render() {
    const { allOrder, newOrder, processedOrder, isLoading } = this.state;

    let order = "";
    if (allOrder) {
      order = (
        <div className="card_wrapper u-margin-top-32 ">
          <div className="card">
            <Card sectioned>
              <h1 className="heading">All Orders</h1>
              <p className="order_count">{allOrder}</p>
            </Card>
          </div>
          <div className="card">
            <Card sectioned>
              <h1 className="heading">Recent Orders</h1>
              <p className="order_count">{newOrder}</p>
            </Card>
          </div>
          <div className="card">
            <Card sectioned>
              <h1 className="heading">Processed Orders</h1>
              <p className="order_count">{processedOrder}</p>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <section className="dashboard">
          <h2 className="heading-primary">Home</h2>
          {isLoading ? <Spinner /> : ""}
          {order}
        </section>
        <Modal
          title="Upgrade to ICS 2.0"
          visible={this.state.showNotification}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="80%"
          height="50%"
        >
          <div className="modal-data">
            <div>
              <div><p>We have done changes in Shopify app as per the ICS 2.0 (Import Control System version 2.0) of EU Regulation</p></div>
              <div><p>European Union is implementing ICS2.0 (Import Control System) from 15th March 2021, to further regulate the provision of complete & accurate customs data. This is to optimize security & safety screening and to prevent any piracy and unfair shipments and ensure full collection of duties and taxes.  The changes will be applicable for all the shipments destined to European countries, Norway, Switzerland, & shipments transiting via Europe (Ex: United Kingdom & United States of America)</p></div>
              <div><p>To be ICS2.0 compliant & to avoid shipment delays, there are two key elements, which needs a change.</p>
                <p>€ Providing DHL an accurate Goods Description on your waybill. This will enable EU Customs to identify the goods based on the data provided and conduct security & safety screening without stopping your shipment.</p>
                <p>€ Providing DHL your Commercial Invoice data electronically. Your Invoice should contain all the mandatory data fields as prescribed by ICS2.0. (Consignor Name| Consignor Address | Consignee Name | Consignee Address | Detailed Line Item Description of Goods | Weight | Pieces + HAWB number)  This will help us to clear your shipments through EU Customs accordingly.</p>
              </div>
              <div><p>At DHL Express, as a part of continuous improvement, we have made necessary changes in our DHL Web services in order cater to the new European Union Customs Regulatory changes. For that please move to the latest Shopify version ASAP as backend mapping has been done with our DHL web services.</p></div>
            </div>
          </div> 
        </Modal>
      </Fragment>
    );
  }
}

export default Dashboard;
