export default function checkQuantitiesByID(objects, quantityById) {
    for (var i = 0; i < objects.length; i++) {
      var id = objects[i].id;
      var quantity = objects[i].quantity;
      
      if (quantityById[id] !== quantity) {
        return false;
      }
    }
  
    return true;
  }
  
  