import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";

import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";

import "@shopify/polaris/build/esm/styles.css";

ReactDOM.render(
  <AppProvider i18n={enTranslations}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
