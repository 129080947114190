import React, { Component, Fragment } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import AdminHome from "./components/AdminHome";

export default class Router extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/admin_home/:store/:token" component={AdminHome} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

// /admin_home/mmtteststore9.myshopify.com
