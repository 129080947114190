import React, { useEffect, useState } from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import {
  AlphaCard,
  Box,
  Button,
  Spinner,
  VerticalStack,
} from "@shopify/polaris";
import axios from "../axios";
import OrderTable from "./tables/OrderTable";
import BoxTable from "./tables/BoxTable";
import countObjectsByID from "./Utils/countObjectsByID";
import checkQuantitiesByID from "./Utils/checkQuantitiesByID";
import PopupData from "./UI/PopupData";

const EditShip2 = (props) => {
  const [data, setData] = useState({
    pieces: 0,
    NFEI_FLAG: "YES",
    dateofsupply: "",
  });

  const [boxes, setBoxes] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [fieldError, setFieldError] = useState({});
  const [hsValCd, setHsValCd] = useState(71179090);
  const [shipToUS, setShipToUs] = useState(true);
  const [shopUrl, setShopUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [boxOrder, setBoxOrder] = useState([]);
  const [error, setError] = useState(true);
  const [confirmShipData, setConfirmShipData] = useState([]);

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.data &&
      props.location.state.data.length > 0
    ) {
      const firstData = props.location.state.data[0];
      const activePieces = firstData.line_items.filter(r => r.fulfillable_quantity);
      setData((prevState) => ({
        ...prevState,
        ...firstData,
        pieces: activePieces.length,
      }));
    }
    setShopUrl(props.match.params.store);
  }, [props]);
  //  console.log(shopUrl);

  useEffect(() => {
    const lineDataArray = data.line_items?.map(({ id, fulfillable_quantity }) => ({
      id,
      quantity: fulfillable_quantity,
    }));
    setLineData(lineDataArray);
  }, [data]);

  // to check fields empty or not
  const isEmpty = (obj, fe) => {
    if (obj[fe] == null || obj[fe].length <= 0) {
      setFieldError((prev) => ({
        ...prev,
        [obj.id]: { ...prev[obj.id], [fe]: true },
      }));
      return true;
    } else {
      setFieldError((prev) => ({
        ...prev,
        [obj.id]: { ...prev[obj.id], [fe]: false },
      }));
      return false;
    }
  };
  // console.log(error);
  const fieldCheck = () => {
    
    const { count, totalQuantity } = countObjectsByID(boxes);

    // Data fields to be checked for empty
    const dataFields = ["pieces"];

    // Boxes fields to be checked for empty
    const boxFields = [
      "title",
      "content_type_selected",
      "hsncode",
      "fobValue",
      "grams",
      "quantity",
      "ShipPieceUOM",
      "ManufactureCountryCode",
      "ShipPieceIGSTPercentage",
      "ShipPieceCESS",
    ];

    boxes.forEach((box) => {
      boxFields.forEach((item) => {
        isEmpty(box, item);
      });
    });

    const hasEmptyFields = boxes.some((box) =>
      boxFields.some((item) => box[item] == null || box[item].length <= 0)
    );

    if (data.pieces == null || data.pieces.length <= 0) {
      setFieldError((prev) => ({ ...prev, pieces: true }));
      alert("Pieces can't be empty");
      return false;
    } else if (!checkQuantitiesByID(lineData, totalQuantity)) {
      alert("Please check the quantity!");
      return false;
    } else if (hasEmptyFields) {
      return false;
    }

    setFieldError((prev) => ({ ...prev, pieces: false }));
    return true;
  };

  // const fobCheck = () => {
  //   const isValid = boxes?.every((item) => {
  //     console.log("fobCheck");
  //     // console.log(item.quantity, item.price, item.total_discount);
  //     const minFob = (
  //       parseFloat(item?.price) * item?.quantity -
  //       parseFloat(item?.total_discount)
  //     ).toFixed(2);

  //     if (parseFloat(item?.fobValue) < minFob) {
  //       return false;
  //     }

  //     return true;
  //   });

  //   if (!isValid) {
  //     alert("invalid fob value");
  //   }

  //   return isValid;
  // };

  const postDataMethod = async (req_param) => {
    setIsLoading(true);

    const res = await axios.post(`/admin/orderShip/${shopUrl}`, {
      data: req_param,
    });

    if (res.data[0]?.status === true) {
      setConfirmShipData(res.data[0]);
    } else {
      let err_msg = "something went wrong";

      if (res.data[0] && res.data[0].courier_err_msg) {
        err_msg = res.data[0].courier_err_msg.replace(
          /(<([^>]+)>|\n |\s{2,})/gi,
          " "
        );
      }

      alert(err_msg);
    }
    setIsLoading(false);
  };

  const handleConfirmShip = () => {
    const { count, totalQuantity } = countObjectsByID(boxes);
    // sending Data
    let total_items = Object.values(totalQuantity).reduce(
      (sum, value) => sum + value,
      0
    );

    let unitPrice = data.total_line_items_price;
    let totalPrice = parseFloat(total_items * unitPrice);

    // shipment Data
    const shipmentData = {
      distanceInKms: "?",
      productData: [
        {
          HSCode: hsValCd,
          SerialNumber: 1,
          FOBValue: unitPrice,
          Description: data.description,
          IsUnderMEISScheme: "0",
          // CommodityType: content_type_selected,
          ShipPieceWt: 20,
          ShipPieceDepth: 47,
          ShipPieceWidth: 38,
          ShipPieceHeight: 1,
          Qty: total_items,
        },
      ],
    };

    // clientData
    let BoxOrder = [];
    let hsncode = [];
    let fobValue = [];
    let commodity_type = [];
    let Description = [];
    let ItemWeight = [];
    let itemQuantity = [];
    let ShipPieceUOM = [];
    let ShipPieceCESS = [];
    let ShipPieceIGST = [];
    let ShipPieceTaxableValue = [];
    let ManufactureCountryName = [];
    let ManufactureCountryCode = [];
    let CommodityCode = [];
    let LicenseNumber = [];
    let ExpiryDate = [];
    let ShipPieceIGSTPercentage = [];
    let BoxIds = [];

    if (fieldCheck()) {
      boxes.forEach((box) => {
        BoxIds.push(box.id);
        BoxOrder.push(box.box_no);
        hsncode.push(box.hsncode);
        fobValue.push(box.fobValue); //fob value input
        commodity_type.push(box.content_type_selected);
        Description.push(box.description);
        itemQuantity.push(box.fulfillable_quantity);
        ItemWeight.push(box.grams);
        ShipPieceUOM.push(box.ShipPieceUOM);
        ShipPieceCESS.push(box.ShipPieceCESS);
        ShipPieceIGST.push(box.ShipPieceIGST);
        ShipPieceTaxableValue.push(box.ShipPieceTaxableValue);
        ManufactureCountryName.push(box.ManufactureCountryName);
        ManufactureCountryCode.push(box.ManufactureCountryCode);
        CommodityCode.push(box.CommodityCode);
        LicenseNumber.push(box.LicenseNumber);
        ExpiryDate.push(box.ExpiryDate);
        ShipPieceIGSTPercentage.push(box.ShipPieceIGSTPercentage);
      });

      // console.log(hsncode);

      let clientData = {};
      clientData["FobValue"] = fobValue.toString();
      clientData["HSNCode"] = hsncode.toString();
      clientData["CommodityType"] = commodity_type.toString();
      clientData["Description"] = Description.toString();
      clientData["BoxOrder"] = BoxOrder.toString();
      clientData["ItemWeight"] = ItemWeight.toString();
      clientData["ShipPieceUOM"] = ShipPieceUOM.toString();
      clientData["ShipPieceCESS"] = ShipPieceCESS.toString();
      clientData["ItemQuantity"] = itemQuantity.toString();
      clientData["BoxIds"] = BoxIds.toString();
      //clientData["ShipPieceTaxableValue"] = ShipPieceTaxableValue.toString();
      clientData["ManufactureCountryName"] = ManufactureCountryName.toString();
      clientData["ManufactureCountryCode"] = ManufactureCountryCode.toString();
      clientData["CommodityCode"] = CommodityCode.toString();
      clientData["LicenseNumber"] = LicenseNumber?.toString();
      clientData["ExpiryDate"] = ExpiryDate.toString();
      clientData["ShipPieceIGSTPercentage"] =
        ShipPieceIGSTPercentage.toString();
      clientData["Invoicevalueinword"] =
        data?.Invoicevalueinword?.toString() || "";
      clientData["dateofsupply"] = data.dateofsupply?.toString() || "";
      clientData["ReverseCharge"] = data.ReverseCharge?.toString() || "";
      clientData["PayerGSTVAT"] = data.PayerGSTVAT?.toString() || "";
      clientData["FreightCharge"] = data.FreightCharge?.toString() || "";
      clientData["InvoiceNumber"] = data.InvoiceNumber?.toString() || "";
      clientData["InsuranceCharge"] = data.InsuranceCharge?.toString() || "";
      clientData["DDutyVatCharge"] = data.DDutyVatCharge?.toString() || "";
      clientData["InsuredAmount"] = data.InsuredAmount;
      clientData["NFEI_FLAG"] = data.NFEI_FLAG;

      // dataObj
      const line_item2 = {
        custName: `${
          data.shipping_address.first_name +
          " " +
          data.shipping_address.last_name
        }`,
        custOrderName: data.name,
        custOrderStatus: data.fulfillment_status,
        invoice_no: data.name,
        ShipMentContent: data.description,
        shipment_value: totalPrice,
        description: data.description,
        pinCodeEx: data?.shipping_address?.zip,
      };

      // MainData
      const sendData = {
        order_id: data.id,
        dataObj: line_item2,
        dhlShipmentData: shipmentData,
        ConsigneeDivCode: shipToUS,
        isDhlShipment: true,
        boxCount: data.pieces,
        clientData: clientData,
      };

      let req_param = [];
      req_param.push(sendData);

      postDataMethod(req_param);
    }
  };

  return (
    // <AppProvider i18n={enTranslations}>
    <VerticalStack gap={"5"}>
      {/* modal confirm ship response  */}
      {confirmShipData?.length != 0 &&
        Object.keys(confirmShipData).length >= 1 && (
          <PopupData res={confirmShipData} shopUrl={shopUrl} />
        )}

      {/* order table */}
      <AlphaCard padding={"2"}>
        <OrderTable
          data={data}
          setData={setData}
          fieldError={fieldError}
          pieces={data.line_items?.pieces}
        />
      </AlphaCard>

      {/*   Box tables  */}
      <AlphaCard padding={"2"}>
        <BoxTable
          data={data}
          setData={setData}
          boxes={boxes}
          setBoxes={setBoxes}
          fieldError={fieldError}
        />
      </AlphaCard>

      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner />
        </div>
      )}

      {/* buttons */}
      <Box>
        <div
          style={{
            margin: "10px 0px",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button monochrome onClick={handleConfirmShip}>
            Confirm Shipment
          </Button>
        </div>
      </Box>
    </VerticalStack>
    // </AppProvider>
  );
};

export default EditShip2;
