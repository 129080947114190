import { AlphaCard, IndexTable, Modal, Button } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

const PopupData = ({ res, shopUrl }) => {
  const [active, setActive] = useState(true);
  const history = useHistory();

  const handleChange = useCallback(() => {

    setActive(!active);
    history.push(`/admin_home/${shopUrl}/order`);
  }, [active]);

  //   const activator = <Button onClick={handleChange}>Open</Button>;

  return (
    <Modal large open={active} onClose={handleChange} title="Shippment Report">
      <Modal.Section>
        <IndexTable
          resourceName={{ singular: "response", plural: "responses" }}
          selectable={false}
          itemCount={1}
          headings={[
            { title: "Order Name" },
            { title: "Customer Name" },
            { title: "Country" },
            { title: "Total Price" },
            { title: "Courier Name" },
            { title: "Tracking Number" },
            { title: "Invoice PDF" },
            { title: "AWB PDF" },
          ]}
        >
          <IndexTable.Row>
            <IndexTable.Cell>{res.orderName}</IndexTable.Cell>
            <IndexTable.Cell>{res.customerName}</IndexTable.Cell>
            <IndexTable.Cell>{res.countryName}</IndexTable.Cell>
            <IndexTable.Cell>{res.totalPrice}</IndexTable.Cell>
            <IndexTable.Cell>DHL</IndexTable.Cell>
            <IndexTable.Cell>
              {res.courierBody.tracking_number.toString().slice(0,10)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <a href={`http://${window.location.hostname}/admin?pdf=${res.courier_pdf_path}`}  target="_blank">
                Download
              </a>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <a href={res.awb_path} target="_blank">
                Download
              </a>
            </IndexTable.Cell>
          </IndexTable.Row>
        </IndexTable>
      </Modal.Section>
    </Modal>
  );
};

export default PopupData;
