import React, { Component, Fragment } from "react";
import {
  Card,
  ButtonGroup,
  Button,
  Tooltip,
  Link,
  Pagination,
  Badge,
  Tabs,
  Frame,
  Toast
} from "@shopify/polaris";
import { renderToString } from "react-dom/server";

import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import {
  drawDOM,
  exportPDF,
  DrawOptions,
  Group,
} from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import axios from "../../axios";
import Spinner from "../UI/Spinner";
import "../../Sass/pages/order.scss";
import Invoice from "../Invoice";
import Checkbox from "./Checkbox";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      orders: "",
      nextPageUrl: "",
      prevPageUrl: "",
      shop_url: "",
      selectFilter: "",
      searchOrder: "",
      export_data: "",
      selectedOrder: "",
      optionSelected: "",
      selected: 0,
      last_page: null,
      toast:false,
      toastMsg:""
    };
  }

  async componentDidMount() {
    let shop_url = this.props.match.params.store;
    this.setState({
      ...this.state,
      isLoading: true,
      shop_url,
    });

    try {
      const res = await axios.get(`/admin/DHLOrders/${shop_url}`);
      // console.log(res);
      let data = res.data;
      //Save data for checkbxo
      let orders = [];
      data.orders.map((item) => {
        item["isChecked"] = false;

        //console.log("checkbox", item.isChecked);
        orders.push(item);
      });

      // console.log("select order", selectedOrder);
      this.setState({
        ...this.state,
        orders: orders,
        nextPageUrl: data.next_page_url,
        prevPageUrl: data.prev_page_url,
        last_page: data.last_page,
        isLoading: false,
        // selectedOrder: selectedOrder
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
      //console.log(error);
    }
  }



  //Toast msg
   showToastMsg = (message) => {
    let msg = "Success";
    if (message) msg = message;
    this.setState({
      ...this.state,
      toast:true,
      toastMsg:msg
    })
  };
   toggleActive = () => {
     this.setState({
       ...this.state,
       toast:false
     })
   }

  //PAGINATION
  //Next page
  nextPage = async () => {
    const { nextPageUrl, selectFilter } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });

    try {
      const res = await axios.get(nextPageUrl, {
        params: {
          status: selectFilter,
        },
      });
      let data = res.data;
      //console.log("Next Page data", res.data);
      this.setState({
        ...this.state,
        orders: data.orders,
        nextPageUrl: data.next_page_url,
        prevPageUrl: data.prev_page_url,
        last_page: data.last_page,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
    }
  };
  //Previous page
  previousPage = async () => {
    const { prevPageUrl, selectFilter } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });

    try {
      const res = await axios.get(prevPageUrl, {
        params: {
          status: selectFilter,
        },
      });
      let data = res.data;
      //console.log("Next Page data", res.data);
      this.setState({
        ...this.state,
        orders: data.orders,
        nextPageUrl: data.next_page_url,
        prevPageUrl: data.prev_page_url,
        last_page: data.last_page,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
    }
  };

  //handelShip
  handelShip = (id) => {
    const { orders, shop_url } = this.state;
    //console.log("ID", id);
    let clickedItem = orders.filter((order) => {
      return order.id === id;
    });
    this.props.history.push(`/admin_home/${shop_url}/edit-ship`, {
      data: clickedItem,
    });
    //console.log("clicked Item", clickedItem);
  };

  //Get Filter Data
  getFilterData = async (filter, type) => {
    const { shop_url } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await axios.get(`/admin/DHLOrders/${shop_url}`, {
        params: {
          [type]: filter,
        },
      });
      //console.log("Filter Data response", res.data);
      let data = res.data;
      this.setState({
        ...this.state,
        isLoading: false,
        orders: data.orders,
        selectFilter: filter,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      //console.log(error);
    }
  };

  //HANDEL ORDER SERACH
  handelSearch = (e) => {
    //console.log(e.target.value);
    let value = e.target.value;
    this.getFilterData(value, "number");

    this.setState({
      ...this.state,
      searchOrder: e.target.value,
    });
  };

  //GET INVOICE
  getInvoice = async (orderName) => {
    //console.log("Order Name", orderName);
    const { shop_url } = this.state;

    try {
      const res = await axios.post(`/orders/invoice/${shop_url}`, {
        name: orderName,
      });
    } catch (error) {}
  };

  clickPdf = async (orderName) => {
    //console.log("Order Name", orderName);
    const { shop_url } = this.state;

    try {
      const res = await axios.post(`/orders/invoice/${shop_url}`, {
        name: orderName,
      });

      let data = res.data;
      console.log("Invoice Response", data);
      if (data) {
        document.getElementById("invoiceSectionAll").innerHTML =
          data.invoiceTemplate;
        document.getElementById("myCanvas").style.display = "block";
        drawDOM(this._element, { paperSize: "A4", scale: 0.6 })
          .then((group) => {
            return exportPDF(group);
          })
          .then((url) => {
            saveAs(url, "invoice.pdf");
            document.getElementById("myCanvas").style.display = "none";
          });

        // kendo.drawing
        //   .drawDOM("#myCanvas", {
        //     paperSize: "A4",
        //     margin: { top: "1cm", bottom: "1cm" },
        //     scale: 0.6
        //   })
        //   .then(function(group) {
        //     document.getElementById("myCanvas").style.display = "none";
        //     kendo.drawing.pdf.saveAs(group, "Invoice" + name + ".pdf");
        //     document.getElementById("myCanvas").style.display = "none";
        //   });
      }
      // if (data) {
      //   // const string = renderToString(<Invoice data={data} />);
      //   const pdf = new jsPDF("p", "mm", "a4");
      //   const columns = [
      //     "SOW Creation Date",
      //     "SOW Start Date",
      //     "Project",
      //     "Last Updated",
      //     "SOW End Date"
      //   ];
      //   var rows = [
      //     [
      //       "Dec 13, 2017",
      //       "Jan 1, 2018",
      //       "ABC Connect - ABCXYZ",
      //       "Dec 13, 2017",
      //       "Dec 31, 2018"
      //     ]
      //   ];
      //   // pdf.fromHTML(data.invoiceTemplate);
      //   pdf.text(data.invoiceTemplate, 20, 20);

      //   pdf.save("invoice");
      // }
    } catch (error) {
      //console.log(error);
    }
  };

  //BULK Shipping Checkbox Handling

  handleAllChecked = (event) => {
    //console.log(event.target.value);
    let orders = this.state.orders;
    // let orders = allOrder.filter(o => {
    //   return o.fulfillment_status != "fulfilled";
    // });

    orders.forEach((order) => (order.isChecked = event.target.checked));
    this.setState({ orders });
  };

  handleCheckChieldElement = (event) => {
    //console.log(event.target.value);
    let orders = this.state.orders;
    orders.forEach((order) => {
      if (order._id === event.target.value)
        order.isChecked = event.target.checked;
    });

    //Get the number of checked orders
    let checkedOrder = orders.filter((o) => {
      return o.isChecked === true;
    });
    //console.log(checkedOrder);
    // if (checkedOrder.length >= 1) {
    //   this.setState({ showShip: true });
    // } else {
    //   this.setState({ showShip: false });
    // }
    this.setState({ orders });
  };

  //Handel Bulk ship
  handelBulkShip = () => {
    const { orders, shop_url } = this.state;
    //Filter the Ischecked orders
    let checkedOrders = orders.filter((order) => {
      return order.isChecked;
    });

    //filter the unfullfilled orders
    let unfulfilled_orders = checkedOrders.filter((o) => {
      return o.fulfillment_status != "fulfilled";
    });

    let unfulfilled_orders_list = [];
    unfulfilled_orders.map((u) => {
      u["description"] = "";
      u.line_items.map((i) => {
        i["content_type_selected"] = "Others";
        i["hsncode"] = "";
        i["description"] = "";
      });
      unfulfilled_orders_list.push(u);
    });

    this.props.history.push(`/admin_home/${shop_url}/edit-ship`, {
      data: unfulfilled_orders_list,
    });
    //console.log("Bulk ship data", unfulfilled_orders_list);
  };

  //handleTabChange
  handleTabChange = (selectedTabIndex) => {
    //console.log(selectedTabIndex);
    let index = selectedTabIndex;
    if (index == 0) {
      this.getFilterData("any", "status");
    }
    if (index == 1) {
      this.getFilterData("fulfilled", "status");
    }
    if (index == 2) {
      this.getFilterData("fulfilled_dhl", "status");
    }
    if (index == 3) {
      this.getFilterData("unfulfilled", "status");
    }

    this.setState({
      ...this.state,
      selected: selectedTabIndex,
    });
  };


  syncOrders = () => {
    this.showToastMsg("order sync initiated");
    axios.get(`https://orderlabelgenerate.marmeto.com/admin/sync_order/${this.state.shop_url}`).then(res => {
      
        window.location.reload();
      
      
    })

    
  }


  render() {
    const {
      orders,
      isLoading,
      selectFilter,
      searchOrder,
      selected,
      toast,
      toastMsg
    } = this.state;

    const toastMarkup = toast ? (
      <Toast content={toastMsg}  onDismiss={this.toggleActive} />
    ) : null;

    //Check the ship button will show or not
    let showShip = false;
    if (orders) {
      for (let i = 0; i < orders.length; i++) {
        if (orders[i].isChecked) {
          showShip = true;
          break;
        }
      }
    }

    //console.log("ShowShip", showShip);

    console.log("Order State", this.state);
    const tabs = [
      {
        id: "all-orders",
        content: "All",
        accessibilityLabel: "All customers",
        panelID: "all-orders-content",
      },
      {
        id: "fulfilled",
        content: "Fulfilled",
        panelID: "fulfilled",
      },
      {
        id: "fulfilled-by-dhl",
        content: "Fulfilled By DHL",
        panelID: "fullfilled-by-dhl",
      },
      {
        id: "unfulfilled",
        content: "Unfulfilled",
        panelID: "unfulfilled-content",
      },
    ];

    let order_list = "";
    if (orders) {
      order_list = orders.map((order) => {
        let {
          id,
          customer,
          line_items,
          shipping_address,
          store_url,
          name,
          date,
          fulfillment_status,
          courierPdfPath,
          courierPdfStatus,
          courier_awb,
        } = order;

        let shipping_address2 = shipping_address ? shipping_address : "_";

        let customer2 = customer || "";
        let fullname =
          customer2.first_name && customer2.last_name
            ? `${customer2.first_name} ${customer2.last_name}`
            : "-";
        let country = shipping_address2.country
          ? shipping_address2.country
          : "-";
        let total_quantity = line_items ? line_items.length : "-";
        let status =
          fulfillment_status === "fulfilled" ? "Fulfilled" : "Unfulfilled";
        console.log("store url", store_url);

        //Check if the Fullfillment status and Disable the Ship Button
        let fulfilled = false;
        fulfilled = fulfillment_status === "fulfilled" ? true : false;

        //Check for Unfullfilled
        //If Fullfillment status is "fullfilled" then enable AWB and Invoice
        let Unfulfilled = true;
        Unfulfilled = fulfillment_status === "fulfilled" ? false : true;

        //Generate AWB URL
        let awbUrl = "";
        awbUrl = courierPdfStatus === "true" ? courierPdfPath : "";
        // let awbUrl = `http://"  window.location.hostname + "/admin?pdf=" + awbPath;
        // `
        let courier_awb_no = courierPdfStatus === "true" ? courier_awb : "";

        return (
          <tr key={id}>
            <td>
              <Checkbox
                key={id}
                handleCheckChieldElement={this.handleCheckChieldElement}
                {...order}
                isDisable={fulfilled}
              />
            </td>
            <td>{fullname}</td>
            <td>{country}</td>
            <td>{total_quantity}</td>
            <td>
              <a
                href={`https://${store_url}/admin/orders/${id}`}
                target="_blank"
              >
                {" "}
                {name}
              </a>
            </td>
            <td>{date}</td>
            <td>
              <Badge
                status={fulfillment_status === "fulfilled" ? "success" : ""}
                // status="success"
              >
                {status}
              </Badge>
            </td>
            <td>
              <ButtonGroup segmented>
                <Button
                  disabled={fulfilled}
                  onClick={() => this.handelShip(id)}
                >
                  Ship
                </Button>
                <Tooltip preferredPosition="above" content="AWB">
                  <Button disabled={Unfulfilled}>
                    <a
                      href={`http://www.dhlindiaplugin.com/FileUpload/${courier_awb_no}.pdf`}
                      target="_blank"
                    >
                      <span className="Polaris-Button__Content">
                        <img src="/img/aeroplane.png" />
                      </span>
                    </a>
                  </Button>
                </Tooltip>
                <Tooltip preferredPosition="above" content="Invoice">
                <Button disabled={Unfulfilled}>
                    <a
                      href={`http://${window.location.hostname}/admin?pdf=${awbUrl}`}
                      target="_blank"
                    >
                      <span className="Polaris-Button__Content">
                      <img src="/img/invoice.png" />
                      </span>
                    </a>
                  </Button>

                  {/*<Button
                    disabled={Unfulfilled}
                    onClick={() => this.clickPdf(name)}
                  >
                    <span className="Polaris-Button__Content">
                      {" "}
                      <img src="/img/invoice.png" />
                    </span>
                  </Button> */}
                  {/* <Button
                    // disabled={Unfulfilled}
                    onClick={this.clickPdf}
                  >
                    <span className="Polaris-Button__Content">
                      {" "}
                      <img src="/img/invoice.png" />
                    </span>
                  </Button> */}
                </Tooltip>
              </ButtonGroup>
            </td>
          </tr>
        );
      });
    }

    //Export CSV data
    let csv_data = [];
    if (orders) {
      csv_data = orders.map((order) => {
        let details = "";

        let {
          id,
          customer,
          line_items,
          store_url,
          name,
          created_at,
          fulfillment_status,
          courierPdfPath,
          courierPdfStatus,
          courier_awb,
          contact_email,
          total_price,
          total_price_usd,
          total_weight,
          total_tax,
          user_id,
          total_line_items_price,
          source_name,
          shipping_address,
          gateway,
          city,
          address1,
          address2,
        } = order;

        let shipping_address2 = shipping_address ? shipping_address : "_";
        let customer2 = customer || "";
        let fullname =
          customer2.first_name && customer2.last_name
            ? `${customer2.first_name} ${customer2.last_name}`
            : "-";
        let country = shipping_address2.country
          ? shipping_address2.country
          : "-";
        let total_quantity = line_items ? line_items.length : "-";
        let date = created_at;
        let status =
          fulfillment_status === "fulfilled" ? "Fulfilled" : "Unfulfilled";

        details = {
          Order_name: name,
          Customer_name: fullname,
          country: country,
          total_quantity: total_quantity,
          date: date,
          status: status,
          contact_email: contact_email,
          total_price: total_price,
          total_price_usd: total_price_usd,
          total_weight: total_weight,
          total_tax: total_tax,
          gateway: gateway,
          user_id: user_id,
          total_line_items_price: total_line_items_price,
          source_name: source_name,
          courier_awb: courier_awb,
          // shipping_address: [shipping_address],
          city: city,
          address1: address1,
          address2: address2,
        };
        return details;
      });
      //console.log(transaction_data);
      //console.log("csv data", csv_data);
    }

    return (
      <Fragment>
        <Frame>
          {toastMarkup}
        <div className="order">
          <h2 className="heading-primary">Orders</h2>
          <div className="u-margin-top-32">
            <Card>
              <Tabs
                tabs={tabs}
                selected={selected}
                onSelect={this.handleTabChange}
              ></Tabs>

              <div className="content">
                <div className="table_filter">
                  <div className="filter_wrapper">
                    <div className="search_filter">
                      <div className="button_group">
                        <div className="group_item">
                          <form>
                            <div className="Polaris-TextField">
                              <div className="Polaris-TextField__Prefix">
                                <span className="Polaris-Filters__SearchIcon">
                                  <span className="Polaris-Icon">
                                    <svg
                                      viewBox="0 0 20 20"
                                      className="Polaris-Icon__Svg"
                                      focusable="false"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m9.707 4.293l-4.82-4.82A5.968 5.968 0 0 0 14 8 6 6 0 0 0 2 8a6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414"
                                        fillRule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="Polaris-TextField__Input search_input"
                                placeholder="Search by Order ID"
                                value={searchOrder}
                                onChange={this.handelSearch}
                              />
                              <div className="Polaris-TextField__Backdrop"></div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="export_filter">
                      <CSVLink
                        data={csv_data}
                        filename={"my-file.csv"}
                        className="Polaris-Button"
                        target="_blank"
                      >
                        Export CSV
                      </CSVLink>

                       <span style= {{margin: "0 10px"}}> 
                       <Button primary onClick ={this.syncOrders}>Sync</Button>
                       </span>
                      

                      {showShip ? (
                        <div className="shipOrder">
                          <Button onClick={this.handelBulkShip}>Ship</Button>
                        </div>
                      ) : (
                        ""
                      )}


                    </div>
                  </div>
                </div>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={this.handleAllChecked}
                            value="checkedall"
                          />
                        </th>
                        <th>Customer </th>
                        <th>Country </th>
                        <th>Total Qty </th>
                        <th>Order ID </th>
                        <th>Date </th>
                        <th>Status </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{order_list}</tbody>
                  </table>
                </div>
                {isLoading ? <Spinner /> : ""}

                <div className="pagination">
                  <Pagination
                    hasPrevious={this.state.last_page > 0 ? true : false}
                    onPrevious={this.previousPage}
                    hasNext
                    onNext={this.nextPage}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div
          id="myCanvas"
          ref={(div) => {
            this._element = div;
          }}
        >
          <div id="invoiceSectionAll"></div>
        </div>
        </Frame>
      </Fragment>
    );
  }
}

export default Order;
