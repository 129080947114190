import React, { Component } from "react";
import "../Sass/pages/order.scss";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ""
    };
  }

  componentDidMount() {
    console.log("Invoice", this.props.data);
    this.setState({
      ...this.state,
      data: this.props.data
    });
  }
  invoiceGenerate(data) {
    console.log(" data", data);
    let shipping = data.shipping;
    let settings = data.settings;

    const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };
    return (
      <React.Fragment>
        <h2 className="heading" style={{ color: "black" }}>
          {" "}
          Invoice
        </h2>
        <div className="flex_wrap_one">
          <div className="left">
            <h3>Sender:</h3>
            <p>DHL INDIA</p>
            <p>DHL</p>
            <p>
              {shipping.address1}, {shipping.address2}
            </p>
            <p>Phone:{shipping.phone}</p>
          </div>
          <br />
          <br />
          <div>
            <h3>Receiver:</h3>
            <p>{shipping.name}</p>
            <p>
              {shipping.address1}, {shipping.address2}
            </p>
            <p>
              {shipping.province}, {shipping.city},{shipping.zip}{" "}
            </p>
            <p>{shipping.country}</p>
            <p>{shipping.phone}</p>
          </div>
          <div>
            <h2
              className="heading"
              style={{ color: "black", textAlign: "center" }}
            >
              {" "}
              Invoice Details
            </h2>
            <p>Invoice No: {data.invoiceNo}</p>
            <p>Date: {data.date}</p>
            <p>GST Invoice No : {settings[0].GST} </p>
            <p>GST Invoice Date : {data.date}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { data } = this.state;
    const tableStyle = {
      width: "80%",
      borderCollapse: "collapse",
      border: 0
    };

    const textAlign = {
      textAlign: "center",
      width: "20%"
    };

    return (
      <div className="invoice2" style={tableStyle}>
        {this.invoiceGenerate(this.props.data)}
      </div>
    );
  }
}

export default Invoice;

{
  /* <div className="invoice">
Hey there
<table className="table_one" cellspacing="0" cellpadding="0">
  <thead>
    <tr>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <p>Sender:DHL INDIA </p>
        <p>address1</p>
        <p>address 2</p>
        <p>address3</p>
        <p>Phone: 9937723163</p>
      </td>
      <td>
        <p>Invoice</p>
      </td>
    </tr>
    <tr>
      <td rowSpan="3">
        <p>Receiver: Faruq Khan</p>
        <p>Address</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>Invoice No : 3094</p>
        <p>Date: 22/20/2019</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>GST Invoice No : 3094</p>
        <p>GST Invoice Date: 22/20/2019</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>Reference no : 3094</p>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        <p>Exporter ID : : DUNS Number</p>
      </td>
      <td>
        {" "}
        <p>Exporter Code :</p>{" "}
      </td>
    </tr>
    <tr>
      <td rowSpan="2"></td>
      <td>
        <p>Other Remarks:SAMPLE HAVING NO COMMERCIAL VALUE</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>Waybill Number : 2889119310</p>
      </td>
    </tr>
  </tbody>
</table>
<table className="table_two">
  <thead>
    <tr>
      <th>Full Description of Goods</th>
      <th>Qty(in Pieces)</th>
      <th>Commodity Code</th>
      <th>Unit Value</th>
      <th>Subtotal Value</th>
      <th>Unit Net Weight</th>
      <th>Gross Weight(KG)</th>
      <th>Country of Origin</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Artificial Jewelry (Sample)</td>
      <td>1</td>
      <td>Commudity code</td>
      <td>14.00</td>
      <td>14</td>
      <td>0</td>
      <td>0</td>
      <td>India</td>
    </tr>
  </tbody>
</table>
<table className="table_three">
  <tbody>
    <tr>
      <td>
        <div>
          <span>Total Declared Value :</span> <span>14</span>
        </div>
        <div>
          <span>Total Line Items : :</span> <span>1</span>
        </div>
        <div>
          <span>Payer of GST/VAT:</span> <span>1</span>
        </div>
        <div>
          <span>Harm.Comm.Code :</span> <span>1</span>
        </div>
        <div>
          <span>Invoice Type :</span> <span>PRO</span>
        </div>
        <div>
          <span>Reason for Export :</span> <span>Permanent</span>
        </div>
        <div>
          <span>Other Charges :</span> <span>NA</span>
        </div>
        <div>
          <span>IGST Paid in INR :</span> <span>NA</span>
        </div>
        <div>
          <span>HSN Code :</span> <span>NA</span>
        </div>
      </td>
      <td>
        <div>
          <span>Total Net Weight (KG):</span> <span>14</span>
        </div>
        <div>
          <span>Total Gross Weight :</span> <span>1</span>
        </div>
        <div>
          <span>Currency Code :</span>{" "}
          <span>declaredValueCurrency</span>
        </div>
        <div>
          <span>Terms Of Payment :</span> <span> - </span>
        </div>
        <div>
          <span>Terms of Trade :</span> <span>DAP</span>
        </div>
        <div>
          <span>GSTIN :</span> <span>27AACCK4117D1ZX</span>
        </div>
        <div>
          <span>Against Bond :</span> <span>NA</span>
        </div>

        <div>
          <span>Reason for Transport</span> <span>NA</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
</div> */
}
