import React from "react";

export const CheckBox = props => {
  return (
    <input
      key={props.id}
      onChange={props.handleCheckChieldElement}
      type="checkbox"
      checked={props.isChecked}
      value={props._id}
      disabled={props.isDisable}
    />
  );
};

export default CheckBox;
